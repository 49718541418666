import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Select } from './rental-application';

const RentToOwn = () => (
  <Layout page="rent-to-own">
    <SEO title="Rent-to-Own" />

    <div className="container">
      <div className="content slim">
        <h2>Rent-to-Own Applications</h2>

        <p>
          Get in touch and we'll get back to you as soon as we can. We look
          forward to hearing from you!
        </p>

        <form
          action="https://formspree.io/casey@yellowdogrealestate.ca"
          method="POST"
        >
          <h3>Personal Information</h3>

          <label className="required">
            Name <input type="text" name="name" required />
          </label>
          <label className="required">
            Phone number <input type="text" name="phone" required />
          </label>
          <label className="required">
            Email <input type="email" name="_replyto" required />
          </label>

          <h3>Rental Information</h3>

          <label>
            Current rent amount <input type="text" name="current-rent-amount" />
          </label>
          <label>
            Have you ever been through a bankruptcy/foreclosure?
            <Select name="had-bankruptcy" />
          </label>
          <label>
            Gross annual income amount
            <input type="text" name="gross-annual-income" />
          </label>
          <label>
            How much do you have for a down payment?
            <input type="text" name="downpayment-amount" />
          </label>
          <label>
            Current credit score <input type="text" name="credit-score" />
          </label>
          <label>
            How did you hear about us?
            <textarea name="how-did-you-hear-about-us" />
          </label>

          <input type="text" name="_gotcha" className="heyo" />

          <input type="submit" value="Send" />
        </form>
      </div>
    </div>
  </Layout>
);

export default RentToOwn;
